  .img-logo {
    width: 100px !important;
    height: auto !important;
  }

  /*reeeplanza la clase banner*/
  .page-banner {
    padding-top: 0px !important;
    padding-bottom: 120px !important;
  }

  /*cart bag icon*/
  svg path {
    height: 30px;
    width: 30px;
    transition: all 0.4s ease;
    stroke: var(--primary-color);
    /* Color del borde */
  }

  /*Background Header and Footer*/
  .background-header {
    background: var(--header-footer-color) no-repeat center / cover;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .background-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  footer {
    background: var(--header-footer-color);
    padding-top: 100px;
  }

  /*Fonts Primary (button) and Header/ Footer*/
  .font-primary-color {
    color: var(--font-primary-color) !important;
    font-weight: bold;
  }

  .font-header-footer-color {
    color: var(--font-header-footer-color) !important;
  }

  .font-weight-bold {
    font-weight: bold;
  }

  /*Scrooling top button top*/
  .top-scrolling a:hover {
    background: none !important;
  }

  /*Social Media footer*/
  .copyright ul li a {
    /*color: #fff;*/
    color: var(--font-primary-color) !important;
    background: var(--primary-color) !important;
  }

  .copyright .copy-text {
    font-size: 14px;
    color: var(--font-header-footer-color) !important;
    letter-spacing: 1px;
    padding-top: 10px;
  }

  /*Cursor point*/
  .cursor-pointer {
    cursor: pointer;
  }

  /*button modal close*/
  .button-container {
    text-align: right;
    margin-bottom: 10px;
  }

  .button-margin-10 {
    margin: 10px;
  }

  /*Product quantity field*/
  .quantity-product {
    margin-top: 30px;
    border-bottom: none !important;
    padding-bottom: 30px;
  }

  /*Button quantity up down*/
  .btn-vertical-group {
    display: inline-grid;
    vertical-align: middle;
    margin: 5px;
  }

  .btn-up-down {
    /*height: 24px;*/
    font-size: 16px;
    padding: 1px 10px;
    margin: 2px;
    color: var(--font-primary-color) !important;
    font-weight: bold;
}

.qty-vertical-group{
  margin: 1px;
}

.quantity-input{
  font-size: 16px !important;
}